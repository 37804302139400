export const SEARCH_SAVED_EVENT_TYPE = "SearchSaved";
export const FILTERS_MODAL_OPENED_EVENT_TYPE = "FiltersModalOpened";
export const SECTION_CLICKED_EVENT_TYPE = "SectionClicked";
export const FILTERS_UPDATED_EVENT_TYPE = "FiltersUpdated";
export const MARKET_STATS_VIEWED_EVENT_TYPE = "MarketStatsViewed";
export const MARKET_STATS_CLICKED_EVENT_TYPE = "MarketStatsClicked";
export const MARKET_STATS_DROPDOWN_CLICKED_EVENT_TYPE = "MarketStatsDropdownClicked";

export interface SearchSavedEventPayload {
    section?: string;
    eventType: string;
    serpUrl: string;
}

export interface FiltersModalOpenedEventPayload {
    eventType: string;
    serpUrl: string;
}

export interface FiltersUpdatedEventPayload {
    eventType: string;
    serpUrl: string;
    [key: string]: string | null | undefined;
}

export interface SectionClickedEventPayload {
    eventType: string;
    serpUrl: string;
    sectionName?: string;
    pageViewId: string;
    value?: string;
    sectionId?: string;
}

export interface MarketStatsViewedEventPayload {
    eventType: string;
    serpUrl: string;
    sectionId: string;
    sectionName: string;
    pageViewId: string;
    operationType: string;
    propertyType: string;
    geoId: string;
}

export interface MarketStatsClickedEventPayload {
    eventType: string;
    serpUrl: string;
    sectionId: string;
    sectionName: string;
    pageViewId: string;
    operationType: string;
    propertyType: string;
    geoId: string;
    rooms?: number;
    amenity?: string;
    alternativeGeoId?: string;
}

export interface MarketStatsDropdownClickedEventPayload {
    eventType: string;
    serpUrl: string;
    sectionId: string;
    sectionName: string;
    pageViewId: string;
    operationType: string;
    propertyType: string;
    geoId: string;
    previousGeoId?: string;
    selectedGeoId: string;
    previousPriceEvolutionType?: string;
    selectedPriceEvolutionType: string;
}